ul.ant-table-pagination.ant-pagination {
    margin: 10px 0 0 0
}
td{
    white-space: nowrap !important; 
}

.ant-table-scroll-position-left{
    border: none;
}

.ant-table-column-has-sorters{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}